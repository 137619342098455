import LanguageSelector from '../components/language-selector'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Nav, Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap'

class DocumentationHeader extends Component {
  render() {
    return (
      <div>
        <Navbar color='light'>
          <NavbarBrand className='mr-auto'>Prefab C</NavbarBrand>
        </Navbar>
        <Navbar color='light' light className='navbar-expand' fixed='top'>
          <NavbarBrand tag={Link} to='/'>
            Prefab C
          </NavbarBrand>
          <Nav navbar>
            <NavItem>
              <NavLink tag={Link} to='/implementation'>
                Implementation
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to='/docs'>
                Documentation
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className='ml-auto'>
            <LanguageSelector />
          </Nav>
        </Navbar>
      </div>
    )
  }
}

export default DocumentationHeader
