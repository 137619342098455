import React, { Component } from 'react'
import { Controlled as CodeMirror } from 'react-codemirror2'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { connect } from 'react-redux'
import { Button, Col, Row } from 'reactstrap'

class RequestResponseSample extends Component {
  state = {
    copied: false
  }

  render() {
    const options = {
      language: this.props.language,
      theme: 'github-light',
      lineWrapping: true
    }

    const sampleRowStyle = {
      background: '#f8f9fa',
      paddingBottom: '30px'
    }

    return (
      <Row style={sampleRowStyle} id={this.props.id}>
        <Col xs='6'>
          <CopyToClipboard
            className='float-right'
            onCopy={/* istanbul ignore next */ () => this.setState({ copied: true })}
            text={this.props.request}
          >
            <Button color='secondary' outline size='sm'>
              {/* istanbul ignore next */ this.state.copied ? 'Copied' : 'Copy'}
            </Button>
          </CopyToClipboard>
          <h3>Request</h3>
          <CodeMirror value={this.props.request} options={options} />
        </Col>
        <Col xs='6'>
          <h3>Response</h3>
          <CodeMirror value={this.props.response} options={options} />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    language: state.default.language
  }
}

export default connect(mapStateToProps)(RequestResponseSample)
