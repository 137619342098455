import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Nav, Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap'

class Header extends Component {
  render() {
    return (
      <Navbar color='light' light className='navbar-expand'>
        <NavbarBrand tag={Link} to='/' id='application.navigation.landingLink'>
          Prefab C
        </NavbarBrand>
        <Nav navbar>
          <NavItem>
            <NavLink tag={Link} to='/implementation' id='application.navigation.implementationLink'>
              Implementation
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to='/docs' id='application.navigation.documentationLink'>
              Documentation
            </NavLink>
          </NavItem>
        </Nav>
        <Nav className='ml-auto' navbar>
          <a href={this.props.services.dashboard}>
            <Button outline color='primary'>
              Login!
            </Button>
          </a>
        </Nav>
      </Navbar>
    )
  }
}

const mapStateToProps = state => {
  return { services: state.default.services }
}

export default connect(mapStateToProps)(Header)
