import * as actionCreator from '../actions/action-creator'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Nav, NavLink, NavItem } from 'reactstrap'

class LanguageSelector extends Component {
  constructor(props) {
    super(props)
    this.setLanguage = this.setLanguage.bind(this)
  }

  setLanguage(language) {
    this.props.dispatch(actionCreator.setLanguage(language))
  }

  render() {
    return (
      <Nav className='ml-auto' pills>
        <NavItem>
          <NavLink
            href='#'
            id='application.documentation.languageSelector.shell'
            onClick={() => this.setLanguage('shell')}
            active={this.props.language === 'shell'}
          >
            Shell
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href='#'
            id='application.documentation.languageSelector.ruby'
            onClick={() => this.setLanguage('ruby')}
            active={this.props.language === 'ruby'}
          >
            Ruby
          </NavLink>
        </NavItem>
      </Nav>
    )
  }
}

const mapStateToProps = state => {
  return {
    language: state.default.language
  }
}

export default connect(mapStateToProps)(LanguageSelector)
