import ReactGA from 'react-ga'

export default function() {
  ReactGA.initialize('UA-104518031-1', {
    testMode: process.env.NODE_ENV === 'test'
  })
  ReactGA.plugin.require('Clearbit', {
    mapping: {
      companyName: 'dimension1',
      companyDomain: 'dimension2',
      companyTags: 'dimension3',
      companySector: 'dimension4',
      companyIndustryGroup: 'dimension5',
      companyIndustry: 'dimension6',
      companySubIndustry: 'dimension7',
      companyType: 'dimension8',
      companyEmployeesRange: 'dimension9',
      companyAlexaRank: 'dimension10',
      companyCity: 'dimension11',
      companyState: 'dimension12',
      companyCountry: 'dimension13',
      companyFunding: 'dimension14',
      companyTech: 'dimension15'
    }
  })

  const clearbitURL =
    'https://ga.clearbit.com/v1/ga.js?authorization=pk_38fee24f83cadaed1e6c9980e394a94c'
  let script = document.createElement('script')
  script.src = clearbitURL
  script.async = 1
  document.body.appendChild(script)
}
