/* istanbul ignore file */
import React from 'react'

function flatten(text, child) {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}

export function HeadingRenderer(props) {
  const children = React.Children.toArray(props.children)
  const text = children.reduce(flatten, '')
  const slug = text.toLowerCase().replace(/\W/g, '-')
  return React.createElement('h' + props.level, { id: slug }, props.children)
}

export function ImageRenderer(props) {
  return <img className='img-fluid rounded' src={props.src} alt={props.alt} />
}
