import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'

class SignUp extends Component {
  render() {
    return (
      <a href={this.props.services.dashboard}>
        <Button color='primary'>Sign up!</Button>
      </a>
    )
  }
}

const mapStateToProps = state => {
  return { services: state.default.services }
}

export default connect(mapStateToProps)(SignUp)
