import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import { Col, Container, Row } from 'reactstrap'

import Footer from '../components/footer'
import Header from '../components/header'
import SignUp from '../components/sign-up'
import * as markdownUtil from '../utils/markdown'
import contentFilePath from '../markdown/landing.md'

class Landing extends Component {
  state = { content: null }

  componentWillMount() {
    fetch(contentFilePath)
      .then(response => response.text())
      .then(text => this.setState({ content: text }))
  }

  render() {
    return (
      <div id='application.landing'>
        <Header />
        <Container>
          <Row>
            <Col xs={12}>
              <ReactMarkdown
                source={this.state.content}
                renderers={{
                  heading: markdownUtil.HeadingRenderer,
                  image: markdownUtil.ImageRenderer
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <SignUp />
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <Footer />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Landing
