/* istanbul ignore file */
import cookie from 'react-cookies'

export default function() {
  const currentDate = new Date()
  
  const commonState = {
    apiKey: cookie.load('test_api_key') || 'api_key',
    language: cookie.load('language') || 'shell',
    entityID: 'medici',
    entityName: 'Medici Bank',
    bookID: 'medici_usd',
    accountID: 'bank',
    accountTagValue: 'Cosimo',
    date: currentDate.toISOString().slice(0, 10),
    transaction1ID: Math.random().toString(36).substring(7),
    transaction2ID: Math.random().toString(36).substring(7),
    services: {
      api: '',
      dashboard: ''
    }
  }

  switch (process.env.NODE_ENV) {
    case 'development':
      return {
        ...commonState,
        services: {
          api: 'http://api.prefabc.localhost:30000',
          dashboard: 'http://dashboard.prefabc.localhost:3000',
        },
      }
    case 'test':
      return {
        ...commonState,
        services: { api: '', dashboard: '' },
      }
    case 'production':
      return {
        ...commonState,
        services: {
          api: 'https://api.prefabc.com',
          dashboard: 'https://dashboard.prefabc.com'
        },
      }
    default:
      throw new Error('Environment not known: ' + process.env.NODE_ENV)
  }
}
