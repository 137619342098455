import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Badge, Col, Container, Row } from 'reactstrap'

import RequestResponseSample from '../components/request-response-sample'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror-github-light/lib/codemirror-github-light-theme.css'
import 'codemirror/mode/shell/shell'
import 'codemirror/mode/ruby/ruby'

class Content extends Component {
  render() {
    const sampleCode = {
      authorization: {
        request: {
          ruby:
            `
require 'prefabc'
PrefabC.api_key = '` +
            this.props.apiKey +
            `'
`,
          shell:
            `
# The basic authentication header is
# populated with the -u flag with the
# value of username:password. Because
# the API Key is used as your password
# and there is no username, the value
# just looks like a colon (:) followed
# by your API Key.

curl ` +
            this.props.services.api +
            ` -u :` +
            this.props.apiKey +
            `
`
        },
        response: {
          ruby:
            `
` +
            this.props.apiKey +
            `
`,
          shell: `
{
  "message": "Welcome to the API! Your credentials have been accepted. For documentation check out:

https://prefabc.com/docs
"
}
`
        }
      },
      entity: {
        create: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.create_entity(id: '` +
              this.props.entityID +
              `')
`,
            shell:
              `
curl -X POST ` +
              this.props.services.api +
              `/entities -u :` +
              this.props.apiKey +
              ` -d "id=` +
              this.props.entityID +
              `"
`
          },
          response: {
            ruby:
              `
#<PrefabC::Entity:0x00000a
class_name="entity"
default=false
environment="test"
id="` +
              this.props.entityID +
              `"
name=nil
tags={}>
`,
            shell:
              `
{
  "className": "entity",
  "id": "` +
              this.props.entityID +
              `",
  "environment": "test",
  "name": null,
  "default": false,
  "tags": {
  }
}
`
          }
        },
        list: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
PrefabC.entities
`,
            shell:
              `
curl ` +
              this.props.services.api +
              `/entities -u :` +
              this.props.apiKey +
              `
`
          },
          response: {
            ruby:
              `
[#<PrefabC::Entity:0x00000a
class_name="entity"
default=false
environment="test"
id="` +
              this.props.entityID +
              `"
name=nil
tags={}>]
`,
            shell:
              `
[
  {
    "className": "entity",
    "id": "` +
              this.props.entityID +
              `",
    "environment": "test",
    "name": null,
    "default": true,
    "tags": {
    }
  }
]
`
          }
        },
        get: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
PrefabC.entity('` +
              this.props.entityID +
              `')
`,
            shell:
              `
curl ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              ` -u :` +
              this.props.apiKey +
              `
`
          },
          response: {
            ruby:
              `
#<PrefabC::Entity:0x00000a
class_name="entity"
default=false
environment="test"
id="` +
              this.props.entityID +
              `"
name=nil
tags={}>
`,
            shell:
              `
{
  "className": "entity",
  "id": "` +
              this.props.entityID +
              `",
  "environment": "test",
  "name": null,
  "default": true,
  "tags": {
  }
}
`
          }
        },
        update: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.entity('` +
              this.props.entityID +
              `')
entity.name = '` +
              this.props.entityName +
              `'
entity.update!
`,
            shell:
              `
curl -X PATCH ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              ` -u :` +
              this.props.apiKey +
              ` -d "name=` +
              this.props.entityName +
              `"
`
          },
          response: {
            ruby:
              `
#<PrefabC::Entity:0x00000a
class_name="entity"
default=false
environment="test"
id="` +
              this.props.entityID +
              `"
name="` +
              this.props.entityName +
              `"
tags={}>
`,
            shell:
              `
{
  "className": "entity",
  "id": "` +
              this.props.entityID +
              `",
  "environment": "test",
  "name": "` +
              this.props.entityName +
              `",
  "default": true,
  "tags": {
  }
}
`
          }
        },
        delete: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.entity('` +
              this.props.entityID +
              `')
entity.destroy!
`,
            shell:
              `
curl -X DELETE ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              ` -u :` +
              this.props.apiKey +
              `
`
          },
          response: {
            ruby:
              `
#<PrefabC::Entity:0x00000a
class_name="entity"
default=false
deleted=true
environment="test"
id="` +
              this.props.entityID +
              `"
name="` +
              this.props.entityName +
              `"
tags={}>
`,
            shell:
              `
{
  "className": "entity",
  "id": "` +
              this.props.entityID +
              `",
  "environment": "test",
  "name": "Medici Bank",
  "default": false,
  "tags": {
  },
  "deleted": true
}
`
          }
        }
      },
      book: {
        create: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.create_entity(id: '` +
              this.props.entityID +
              `')
book = entity.create_book(id: '` +
              this.props.bookID +
              `', currency: 'USD')
`,
            shell:
              `
curl -X POST ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books -u :` +
              this.props.apiKey +
              ` -d "id=` +
              this.props.bookID +
              `" -d "currency=USD"
`
          },
          response: {
            ruby:
              `
#<PrefabC::Book:0x00000a
class_name="book"
currency=:USD
default=false
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.bookID +
              `"
tags={}>
`,
            shell:
              `
{
  "className": "book",
  "id": "` +
              this.props.bookID +
              `",
  "environment": "test",
  "entity": "` +
              this.props.entityID +
              `",
  "currency": "USD",
  "default": false,
  "tags": {
  }
}
`
          }
        },
        list: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
PrefabC.books
`,
            shell:
              `
curl ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books -u :` +
              this.props.apiKey +
              `
`
          },
          response: {
            ruby:
              `
[#<PrefabC::Book:0x00000a
class_name="book"
currency=:USD
default=false
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.bookID +
              `"
tags={}>]
`,
            shell:
              `
[
  {
    "className": "book",
    "id": "` +
              this.props.bookID +
              `",
    "environment": "test",
    "entity": "` +
              this.props.entityID +
              `",
    "currency": "USD",
    "default": false,
    "tags": {
    }
  }
]
`
          }
        },
        get: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.entity('` +
              this.props.entityID +
              `')
entity.book('` +
              this.props.bookID +
              `')
`,
            shell:
              `
curl ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books/` +
              this.props.bookID +
              `  -u :` +
              this.props.apiKey +
              `
`
          },
          response: {
            ruby:
              `
#<PrefabC::Book:0x00000a
class_name="book"
currency=:USD
default=false
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.bookID +
              `"
tags={}>
`,
            shell:
              `
{
  "className": "book",
  "id": "` +
              this.props.bookID +
              `",
  "environment": "test",
  "entity": "` +
              this.props.entityID +
              `",
  "currency": "USD",
  "default": false,
  "tags": {
  }
}
`
          }
        },
        update: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.entity('` +
              this.props.entityID +
              `')
book = entity.book('` +
              this.props.bookID +
              `')
book.default = true
book.update!
`,
            shell:
              `
curl -X PATCH ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books/` +
              this.props.bookID +
              ` -u :` +
              this.props.apiKey +
              ` -d "default=true"
`
          },
          response: {
            ruby:
              `
#<PrefabC::Book:0x00000a
class_name="book"
currency=:USD
default=true
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.bookID +
              `"
tags={}>
`,
            shell:
              `
{
  "className": "book",
  "id": "` +
              this.props.bookID +
              `",
  "environment": "test",
  "entity": "` +
              this.props.entityID +
              `",
  "currency": "USD",
  "default": true,
  "tags": {
  }
}
`
          }
        },
        delete: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.entity('` +
              this.props.entityID +
              `')
book = entity.book('` +
              this.props.bookID +
              `')
book.destroy!
`,
            shell:
              `
curl -X DELETE ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books/` +
              this.props.bookID +
              ` -u :` +
              this.props.apiKey +
              `
`
          },
          response: {
            ruby:
              `
#<PrefabC::Book:0x00000a
class_name="book"
currency=:USD
default=false
deleted=true
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.bookID +
              `"
tags={}>
`,
            shell:
              `
{
  "className": "book",
  "id": "` +
              this.props.bookID +
              `",
  "environment": "test",
  "entity": "` +
              this.props.entityID +
              `",
  "currency": "USD",
  "default": false,
  "tags": {
  },
  "deleted": true
}
`
          }
        }
      },
      account: {
        create: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.create_entity(id: '` +
              this.props.entityID +
              `')
book = entity.book('` +
              this.props.bookID +
              `')
account = book.create_account(id: '` +
              this.props.accountID +
              `')
`,
            shell:
              `
curl -X POST ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books/` +
              this.props.bookID +
              `/accounts -u :` +
              this.props.apiKey +
              ` -d "id=` +
              this.props.accountID +
              `"
`
          },
          response: {
            ruby:
              `
#<PrefabC::Account:0x00000a
balance=0
book="` +
              this.props.bookID +
              `"
class_name="account"
currency=:USD
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.accountID +
              `"
name=nil
tags={}>
`,
            shell:
              `
{
  "className": "account",
  "id": "` +
              this.props.accountID +
              `",
  "environment": "test",
  "entity": "` +
              this.props.entityID +
              `",
  "book": "` +
              this.props.bookID +
              `",
  "name": null,
  "currency": "USD",
  "balance": 0,
  "tags": {
  }
}
`
          }
        },
        list: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.create_entity(id: '` +
              this.props.entityID +
              `')
book = entity.book('` +
              this.props.bookID +
              `')
book.accounts
`,
            shell:
              `
curl ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books/` +
              this.props.bookID +
              `/accounts -u :` +
              this.props.apiKey +
              `
`
          },
          response: {
            ruby:
              `
[#<PrefabC::Account:0x00000a
balance=0
book="` +
              this.props.bookID +
              `"
class_name="account"
currency=:USD
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.accountID +
              `"
name=nil
tags={}>]
`,
            shell:
              `
[
  {
    "className": "account",
    "id": "` +
              this.props.accountID +
              `",
    "environment": "test",
    "entity": "` +
              this.props.entityID +
              `",
    "book": "` +
              this.props.bookID +
              `",
    "name": null,
    "currency": "USD",
    "balance": 0,
    "tags": {
    }
  }
]
`
          }
        },
        get: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.create_entity(id: '` +
              this.props.entityID +
              `')
book = entity.book('` +
              this.props.bookID +
              `')
book.account('` +
              this.props.accountID +
              `')
`,
            shell:
              `
curl ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books/` +
              this.props.bookID +
              `/accounts/` +
              this.props.accountID +
              ` -u :` +
              this.props.apiKey +
              `
`
          },
          response: {
            ruby:
              `
#<PrefabC::Account:0x00000a
balance=0
book="` +
              this.props.bookID +
              `"
class_name="account"
currency=:USD
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.accountID +
              `"
name=nil
tags={}>
`,
            shell:
              `
{
  "className": "account",
  "id": "` +
              this.props.accountID +
              `",
  "environment": "test",
  "entity": "` +
              this.props.entityID +
              `",
  "book": "acme_usd",
  "name": null,
  "currency": "USD",
  "balance": 0,
  "tags": {
  }
}
`
          }
        },
        update: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.create_entity(id: '` +
              this.props.entityID +
              `')
book = entity.book('` +
              this.props.bookID +
              `')
account = book.account('` +
              this.props.accountID +
              `')
account.tags = { signatories: '` +
              this.props.accountTagValue +
              `' }
account.update!
`,
            shell:
              `
curl -X PATCH ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books/` +
              this.props.bookID +
              `/accounts/` +
              this.props.accountID +
              ` -u :` +
              this.props.apiKey +
              ` -d "tags[signatories]=` +
              this.props.accountTagValue +
              `"
`
          },
          response: {
            ruby:
              `
#<PrefabC::Account:0x00000a
balance=0
book="` +
              this.props.bookID +
              `"
class_name="account"
currency=:USD
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.accountID +
              `"
name=nil
tags={:signatories=>"` +
              this.props.accountTagValue +
              `"}>
`,
            shell:
              `
{
  "className": "account",
  "id": "` +
              this.props.accountID +
              `",
  "environment": "test",
  "entity": "` +
              this.props.entityID +
              `",
  "book": "` +
              this.props.bookID +
              `",
  "currency": "USD",
  "balance": 0,
  "tags": {
      "signatories": "` +
              this.props.accountTagValue +
              `"
  }
}
`
          }
        }
      },
      transaction: {
        create: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.create_entity(id: '` +
              this.props.entityID +
              `')
book = entity.book('` +
              this.props.bookID +
              `')
book.post_transaction_set(
  date: '` +
              this.props.date +
              `',
  tags: {'profit-and-loss-statement-rollup': 'marketing'},
  transactions: [
    { account: 'profit_and_loss', amount: 150 },
    { account: '` +
              this.props.accountID +
              `', amount: -150 },
  ]
)
`,
            shell:
              `
curl ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books/` +
              this.props.bookID +
              `/transactions -u :` +
              this.props.apiKey +
              ` -d "date=` +
              this.props.date +
              `" -d "tags[profit-and-loss-statement-rollup]=marketing" -d "transactions[][account]=` +
              this.props.accountID +
              `" -d "transactions[][id]=` +
              this.props.transaction1ID +
              `" -d "transactions[][amount]=150" -d "transactions[][account]=profit_and_loss" -d "transactions[][id]=` +
              this.props.transaction2ID +
              `" -d "transactions[][amount]=-150"
`
          },
          response: {
            ruby:
              `
[#<PrefabC::Transaction:0x00000a
account="profit_and_loss"
amount=150
book="` +
              this.props.bookID +
              `"
class_name="transaction"
currency=:USD
date=` +
              this.props.date +
              ` 00:00:00 +0000
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.transaction1ID +
              `"
tags={:"profit-and-loss-statement-rollup"=>"marketing"}
transaction_set="api::transactionset_d7aeaf707d8970ed0ecc">,
 #<PrefabC::Transaction:0x00000a
account="` +
              this.props.accountID +
              `"
amount=-150
book="` +
              this.props.bookID +
              `"
class_name="transaction"
currency=:USD
date=` +
              this.props.date +
              ` 00:00:00 +0000
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.transaction2ID +
              `"
tags={:"profit-and-loss-statement-rollup"=>"marketing"}
transaction_set="api::transactionset_d7aeaf707d8970ed0ecc">]
`,
            shell:
              `
[
  {
    "className": "transaction",
    "id": "` +
              this.props.transaction1ID +
              `",
    "environment": "test",
    "entity": "` +
              this.props.entityID +
              `",
    "book": "` +
              this.props.bookID +
              `",
    "account": "` +
              this.props.accountID +
              `",
    "transactionSet": "api::transactionset_d4dfdb618c1e244424cc",
    "date": "` +
              this.props.date +
              `T00:00:00+00:00",
    "amount": 150,
    "currency": "USD",
    "tags": {
      "profit-and-loss-statement-rollup": "marketing"
    }
  },
  {
    "className": "transaction",
    "id": "` +
              this.props.transaction2ID +
              `",
    "environment": "test",
    "entity": "` +
              this.props.entityID +
              `",
    "book": "` +
              this.props.bookID +
              `",
    "account": "profit_and_loss",
    "transactionSet": "api::transactionset_d4dfdb618c1e244424cc",
    "date": "` +
              this.props.date +
              `T00:00:00+00:00",
    "amount": -150,
    "currency": "USD",
    "tags": {
      "profit-and-loss-statement-rollup": "marketing"
    }
  }
]
`
          }
        },
        list: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.entity(id: '` +
              this.props.entityID +
              `')
book = entity.book('` +
              this.props.bookID +
              `')
account = book.account('` +
              this.props.accountID +
              `')
account.transactions
`,
            shell:
              `
curl ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books/` +
              this.props.bookID +
              `/accounts/` +
              this.props.accountID +
              `/transactions -u :` +
              this.props.apiKey +
              `
`
          },
          response: {
            ruby:
              `
[#<PrefabC::Transaction:0x00000a
account="profit_and_loss"
amount=150
book="` +
              this.props.bookID +
              `"
class_name="transaction"
currency=:USD
date=` +
              this.props.date +
              ` 00:00:00 +0000
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.transaction1ID +
              `"
tags={:"profit-and-loss-statement-rollup"=>"marketing"}
transaction_set="api::transactionset_d7aeaf707d8970ed0ecc">,
 #<PrefabC::Transaction:0x00000a
account="` +
              this.props.accountID +
              `"
amount=-150
book="` +
              this.props.bookID +
              `"
class_name="transaction"
currency=:USD
date=` +
              this.props.date +
              ` 00:00:00 +0000
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.transaction2ID +
              `"
tags={:"profit-and-loss-statement-rollup"=>"marketing"}
transaction_set="api::transactionset_d7aeaf707d8970ed0ecc">]
`,
            shell:
              `
[
  {
    "className": "transaction",
    "id": "` +
              this.props.transaction1ID +
              `",
    "environment": "test",
    "entity": "` +
              this.props.entityID +
              `",
    "book": "` +
              this.props.bookID +
              `",
    "account": "` +
              this.props.accountID +
              `",
    "transactionSet": "api::transactionset_d4dfdb618c1e244424cc",
    "date": "` +
              this.props.date +
              `T00:00:00+00:00",
    "amount": 150,
    "currency": "USD",
    "tags": {
      "profit-and-loss-statement-rollup": "marketing"
    }
  }
]
`
          }
        },
        get: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.entity(id: '` +
              this.props.entityID +
              `')
book = entity.book('` +
              this.props.bookID +
              `')
account = book.account('` +
              this.props.accountID +
              `')
account.transaction('` +
              this.props.transaction1ID +
              `')
`,
            shell:
              `
curl ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books/` +
              this.props.bookID +
              `/accounts/` +
              this.props.accountID +
              `/transactions/` +
              this.props.transaction1ID +
              ` -u :` +
              this.props.apiKey +
              `
`
          },
          response: {
            ruby:
              `
#<PrefabC::Transaction:0x00000a
account="profit_and_loss"
amount=150
book="` +
              this.props.bookID +
              `"
class_name="transaction"
currency=:USD
date=` +
              this.props.date +
              ` 00:00:00 +0000
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.transaction1ID +
              `"
tags={:"profit-and-loss-statement-rollup"=>"marketing"}
transaction_set="api::transactionset_d7aeaf707d8970ed0ecc">
`,
            shell:
              `
{
  "className": "transaction",
  "id": "` +
              this.props.transaction1ID +
              `",
  "environment": "test",
  "entity": "` +
              this.props.entityID +
              `",
  "book": "` +
              this.props.bookID +
              `",
  "account": "` +
              this.props.accountID +
              `",
  "transactionSet": "api::transactionset_8d61d91ddecd56e829ba",
  "date": "` +
              this.props.date +
              `T00:00:00+00:00",
  "amount": 150,
  "currency": "USD",
  "tags": {
    "profit-and-loss-statement-rollup": "marketing"
  }
}
`
          }
        },
        update: {
          request: {
            ruby:
              `
require 'prefabc'
PrefabC.api_key = '` +
              this.props.apiKey +
              `'
entity = PrefabC.entity(id: '` +
              this.props.entityID +
              `')
book = entity.book('` +
              this.props.bookID +
              `')
account = book.account('` +
              this.props.accountID +
              `')
transaction = account.transaction('` +
              this.props.transaction1ID +
              `')
transaction.tags = {'profit-and-loss-statement-rollup': 'sales'}
transaction.update!
`,
            shell:
              `
curl -X PATCH ` +
              this.props.services.api +
              `/entities/` +
              this.props.entityID +
              `/books/` +
              this.props.bookID +
              `/accounts/` +
              this.props.accountID +
              `/transactions/` +
              this.props.transaction1ID +
              ` -u :` +
              this.props.apiKey +
              ` -d "tags[profit-and-loss-statement-rollup]=sales"
`
          },
          response: {
            ruby:
              `
#<PrefabC::Transaction:0x00000a
account="profit_and_loss"
amount=150
book="` +
              this.props.bookID +
              `"
class_name="transaction"
currency=:USD
date=` +
              this.props.date +
              ` 00:00:00 +0000
entity="` +
              this.props.entityID +
              `"
environment="test"
id="` +
              this.props.transaction1ID +
              `"
tags={:"profit-and-loss-statement-rollup"=>"sales"}
transaction_set="api::transactionset_d7aeaf707d8970ed0ecc">

`,
            shell:
              `
{
  "className": "transaction",
  "id": "` +
              this.props.transaction1ID +
              `",
  "environment": "test",
  "entity": "` +
              this.props.entityID +
              `",
  "book": "` +
              this.props.bookID +
              `",
  "account": "` +
              this.props.accountID +
              `",
  "transactionSet": "api::transactionset_c53295880899ab7edb9a",
  "date": "` +
              this.props.date +
              `T00:00:00+00:00",
  "amount": 150,
  "currency": "USD",
  "tags": {
    "profit-and-loss-statement-rollup": "sales"
  }
}
`
          }
        }
      }
    }

    return (
      <Container>
        <Row>
          <Col xs='12'>
            <p>
              The API is designed around{' '}
              <a href='https://en.wikipedia.org/wiki/Representational_state_transfer'>
                REST
              </a>{' '}
              using the{' '}
              <a href='https://en.wikipedia.org/wiki/Hypertext_Transfer_Protocol#Request_methods'>
                HTTP methods
              </a>{' '}
              <code>GET</code>, <code>POST</code>, <code>PATCH</code>, and
              <code>DELETE</code>. Standard{' '}
              <a href='https://en.wikipedia.org/wiki/List_of_HTTP_status_codes'>
                HTTP codes
              </a>{' '}
              are used for successful and unsuccessful requests. The API returns{' '}
              <a href='https://www.json.org/'>JSON</a> with values, where
              possible, defined by <a href='https://www.iso.org/'>ISO</a>{' '}
              standards.
            </p>
            <p>
              The main resources are <a href='#entities'>Entities</a>,{' '}
              <a href='#books'>Books</a>, <a href='#accounts'>Accounts</a>, and{' '}
              <a href='#transactions'>Transactions</a>. The resources are
              hierarchal with each Entity having many Books, each Book having
              many Accounts, and each Account having many Transactions.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <h1 id='authentication'>Authentication</h1>
            <p>
              Authentication uses{' '}
              <a href='https://en.wikipedia.org/wiki/Basic_access_authentication'>
                HTTP basic authentication
              </a>
              . Your{' '}
              <a href={this.props.services.dashboard + '/settings/keys'}>
                API Key
              </a>{' '}
              is used as the password (the username is left blank).
            </p>
          </Col>
        </Row>
        <RequestResponseSample
          request={sampleCode.authorization.request[this.props.language]}
          response={sampleCode.authorization.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h1 id='errors'>Errors</h1>
            <p>
              Standard{' '}
              <a href='https://en.wikipedia.org/wiki/List_of_HTTP_status_codes'>
                HTTP error codes
              </a>{' '}
              are used:
            </p>
            <p>
              <code>200 OK</code> The request succeeded.
            </p>
            <p>
              <code>400 Bad Request</code> The request was badly formed. This is
              usually for missing parameters.
            </p>
            <p>
              <code>401 Forbidden</code> You do not have permission to access
              the resource.
            </p>
            <p>
              <code>404 Not Found</code> The resource does not exist. This
              usually means the ID of the resource is incorrect.
            </p>
            <p>
              <code>500 Internal Server Error</code> This means that we messed
              up. It does not happen often.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <h1 id='entities'>Entities</h1>
            <div>
              Entities represent the individual legal entities of a corporate
              group &mdash; for example Google, Google Ventures, and Waymo
              within Alphabet. The Entity object has the following properties:
              <ul>
                <li>
                  <code>id</code>
                  <code>String</code>
                  <Badge color='secondary'>Optional</Badge>
                  The unique identifier for the Entity. This is randomly
                  generated if you don&apos;t set it.
                </li>
                <li>
                  <code>environment</code>
                  <code>Enumerated String</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the environment of the Entity. It can have values of{' '}
                  <code>test</code> and <code>live</code>. The environment is
                  inferred from the API Key used.
                </li>
                <li>
                  <code>name</code>
                  <code>String</code>
                  <Badge color='secondary'>Optional</Badge>The name of the
                  Entity. This is an optional human-readable identifier for the
                  Entity. For example, the <code>ID</code> of an Entity might be
                  set as <code>acme_au</code> while the <code>name</code> of the
                  Entity is <code>Acme Australia Pty, Ltd.</code>.
                </li>
                <li>
                  <code>default</code>
                  <code>Boolean</code>
                  <Badge color='secondary'>Optional</Badge>
                  This field indicates if the Entity is the default Entity for
                  the group.
                </li>
                <li>
                  <code>tags</code>
                  <code>Hash</code>
                  <Badge color='secondary'>Optional</Badge>
                  This is a user-settable set of key-value pairs.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <h2>Create an entity</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='entity-create'
          request={sampleCode.entity.create.request[this.props.language]}
          response={sampleCode.entity.create.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>List entities</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='entity-list'
          request={sampleCode.entity.list.request[this.props.language]}
          response={sampleCode.entity.list.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Retrieve an entity</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='entity-get'
          request={sampleCode.entity.get.request[this.props.language]}
          response={sampleCode.entity.get.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Update an entity</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='entity-update'
          request={sampleCode.entity.update.request[this.props.language]}
          response={sampleCode.entity.update.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Delete an entity</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='entity-delete'
          request={sampleCode.entity.delete.request[this.props.language]}
          response={sampleCode.entity.delete.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h1 id='books'>Books</h1>
            <p>
              Books are the ledgers for entities. They have a currency and every
              transaction in the book will have that same currency. When
              multiple currencies are needed, multiple books are needed; so it
              is completely expected that an entity, Google for example, will
              have a <code>USD</code> book and a <code>CAD</code> book among
              many others. For presentation purposes, these books can be
              combined for financial statements.
            </p>
            <div>
              A Book object has the following properties:
              <ul>
                <li>
                  <code>id</code>
                  <code>String</code>
                  The unique identifier for the Book.
                </li>
                <li>
                  <code>environment</code>
                  <code>Enumerated String</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the environment of the Book. It can have values of{' '}
                  <code>test</code> and <code>live</code>.
                </li>
                <li>
                  <code>entity</code>
                  <code>String</code>
                  This is the ID of the Entity that the Book belongs to.
                </li>
                <li>
                  <code>currency</code>
                  <code>Enumerated String</code>
                  This is the{' '}
                  <a href='https://www.iso.org/iso-4217-currency-codes.html'>
                    ISO 4217 three-letter code
                  </a>{' '}
                  for the currency.
                </li>
                <li>
                  <code>default</code>
                  <code>Boolean</code>
                  This field indicates if the Book is the default Book for the
                  Entity.
                </li>
                <li>
                  <code>tags</code>
                  <code>Hash</code>
                  This is a user-settable set of key-value pairs.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <h2>Create a book</h2>
            <p>This also creates a Profit and Loss Account for the Book.</p>
          </Col>
        </Row>
        <RequestResponseSample
          id='book-create'
          request={sampleCode.book.create.request[this.props.language]}
          response={sampleCode.book.create.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>List books</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='book-list'
          request={sampleCode.book.list.request[this.props.language]}
          response={sampleCode.book.list.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Retrieve a book</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='book-get'
          request={sampleCode.book.get.request[this.props.language]}
          response={sampleCode.book.get.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Update a book</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='book-update'
          request={sampleCode.book.update.request[this.props.language]}
          response={sampleCode.book.update.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Delete a book</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='book-delete'
          request={sampleCode.book.delete.request[this.props.language]}
          response={sampleCode.book.delete.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h1 id='accounts'>Accounts</h1>
            <p>
              Accounts are the T-accounts in each book. Their balances are
              calculated based on the Transactions.
            </p>
            <div>
              An Account object has the following properties:
              <ul>
                <li>
                  <code>id</code>
                  <code>String</code>
                  The unique identifier for the Book.
                </li>
                <li>
                  <code>environment</code>
                  <code>Enumerated String</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the environment of the Account. It can have values of{' '}
                  <code>test</code> and <code>live</code>.
                </li>
                <li>
                  <code>entity</code>
                  <code>String</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the ID of the Entity that the Account belongs to.
                </li>
                <li>
                  <code>book</code>
                  <code>String</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the ID of the Book that the Account belongs to.
                </li>
                <li>
                  <code>currency</code>
                  <code>Enumerated String</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the{' '}
                  <a href='https://www.iso.org/iso-4217-currency-codes.html'>
                    ISO 4217 three-letter code
                  </a>{' '}
                  for the currency.
                </li>
                <li>
                  <code>balance</code>
                  <code>Integer</code>
                  This is a calculated (rather than user-set) field. The balance
                  is the{' '}
                  <a href='https://www.iso.org/iso-4217-currency-codes.html'>
                    minor unit
                  </a>{' '}
                  of the <code>currency</code>.
                </li>
                <li>
                  <code>tags</code>
                  <code>Hash</code>
                  This is a user-settable set of key-value pairs.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <h2>Create an account</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='account-create'
          request={sampleCode.account.create.request[this.props.language]}
          response={sampleCode.account.create.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>List accounts</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='account-list'
          request={sampleCode.account.list.request[this.props.language]}
          response={sampleCode.account.list.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Retrieve an account</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='account-get'
          request={sampleCode.account.get.request[this.props.language]}
          response={sampleCode.account.get.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Update an account</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='account-update'
          request={sampleCode.account.update.request[this.props.language]}
          response={sampleCode.account.update.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Delete an account</h2>
            <p>
              Deleting accounts isn&apos;t supported. Deleting accounts might
              result in a Book being unbalanced. If you're' looking to delete an
              Account so you can recreate it with a different name, you might
              just want to <a href='#account-update'>update the Account</a>.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <h1 id='transactions'>Transactions</h1>
            <p>
              Transactions are the fundamental unit of Prefab C. They are the
              individual entries in Accounts. The amounts of the Transactions in
              an account are used to calculate the balance and the tags on an
              account are used to generate the Profit and Loss Statement. (They
              are also potentially useful for your own analysis.)
            </p>
            <p>
              In order to keep Accounts balanced, Transactions must always be
              part of a set where the amounts balance per side in a book, date,
              and currency. As such, Transactions are created in sets rather
              than individually, and they are created on Books rather than on
              Accounts.
            </p>
            <div>
              A Transaction object has the following properties:
              <ul>
                <li>
                  <code>id</code>
                  <code>String</code>
                  The unique identifier for the Transaction.
                </li>
                <li>
                  <code>environment</code>
                  <code>Enumerated String</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the environment of the Transaction. It can have values
                  of <code>test</code> and <code>live</code>.
                </li>
                <li>
                  <code>entity</code>
                  <code>String</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the ID of the Entity that the Transaction belongs to.
                </li>
                <li>
                  <code>book</code>
                  <code>String</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the ID of the Book that the Transaction belongs to.
                </li>
                <li>
                  <code>account</code>
                  <code>String</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the ID of the Account that the Transaction belongs to.
                </li>
                <li>
                  <code>amount</code>
                  <code>Integer</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the amount of the Transaction given in the{' '}
                  <a href='https://www.iso.org/iso-4217-currency-codes.html'>
                    minor unit
                  </a>
                  of the <code>Currency</code>. Transactions are either debits
                  or credits — debits are positive integers and credits are
                  negative integers.
                </li>
                <li>
                  <code>currency</code>
                  <code>Enumerated String</code>
                  <Badge color='primary'>Immutable</Badge>
                  This is the{' '}
                  <a href='https://www.iso.org/iso-4217-currency-codes.html'>
                    ISO 4217 three-letter code
                  </a>{' '}
                  for the currency.
                </li>
                <li>
                  <code>date</code>
                  <code>Date</code>
                  This is the date of the Transaction (as opposed to the date
                  the Transaction was entered) in{' '}
                  <a href='https://www.iso.org/iso-8601-date-and-time-format.html'>
                    ISO 8601 format
                  </a>
                  .
                </li>
                <li>
                  <code>tags</code>
                  <code>Hash</code>
                  This is a user-settable set of key-value pairs.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <h2>Create a transaction</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='transaction-create'
          request={sampleCode.transaction.create.request[this.props.language]}
          response={sampleCode.transaction.create.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>List transactions</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='transaction-list'
          request={sampleCode.transaction.list.request[this.props.language]}
          response={sampleCode.transaction.list.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Retrieve a transaction</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='transaction-get'
          request={sampleCode.transaction.get.request[this.props.language]}
          response={sampleCode.transaction.get.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Update a transaction</h2>
          </Col>
        </Row>
        <RequestResponseSample
          id='transaction-update'
          request={sampleCode.transaction.update.request[this.props.language]}
          response={sampleCode.transaction.update.response[this.props.language]}
        />
        <Row>
          <Col xs='12'>
            <h2>Delete a transaction</h2>
            <p>
              Deleting transactions isn&apos;t supported. Deleting transactions
              would result in a Book being unbalanced. If you are looking to
              delete a Transaction so you can recreate it with a different ID,
              you might just want to{' '}
              <a href='#transaction-update'>update the Transaction</a>.
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    apiKey: state.default.apiKey,
    language: state.default.language,
    entityID: state.default.entityID,
    entityName: state.default.entityName,
    bookID: state.default.bookID,
    accountID: state.default.accountID,
    accountTagValue: state.default.accountTagValue,
    date: state.default.date,
    transaction1ID: state.default.transaction1ID,
    transaction2ID: state.default.transaction2ID,
    services: state.default.services
  }
}

export default connect(mapStateToProps)(Content)
