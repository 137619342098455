import React, { Component } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'

class DocumentationNavigation extends Component {
  render() {
    return (
      <Nav vertical style={{ position: 'fixed' }}>
        <NavItem>
          <NavLink href='#authentication'>Authentication</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#errors'>Errors</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#entities'>{'Entity'}</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#books'>Book</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#accounts'>Account</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#transactions'>Transaction</NavLink>
        </NavItem>
      </Nav>
    )
  }
}

export default DocumentationNavigation
