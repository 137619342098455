import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'

import Content from '../components/content'
import DocumentationHeader from '../components/documentation-header'
import DocumentationNavigation from '../components/documentation-navigation'
import Footer from '../components/footer'
import SignUp from '../components/sign-up'

class Documentation extends Component {
  render() {
    return (
      <div id='application.documentation'>
        <DocumentationHeader />
        <Container fluid>
          <Row>
            <Col xs='2'>
              <DocumentationNavigation />
            </Col>
            <Col xs='10'>
              <Content />
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <SignUp />
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <Footer />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Documentation
