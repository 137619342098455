import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router'

import Documentation from '../containers/documentation'
import Implementation from '../containers/implementation'
import Landing from '../containers/landing'
import clearbit from '../utils/clearbit'

class Application extends Component {
  constructor() {
    super()
    clearbit()
  }

  render() {
    ReactGA.pageview(this.props.location.pathname)
    return (
      <Switch>
        <Route exact path='/' component={Landing} />
        <Route exact path='/docs' component={Documentation} />
        <Route exact path='/implementation' component={Implementation} />
        <Route component={Landing} />
      </Switch>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

export default withRouter(connect(mapStateToProps)(Application))
