import cookie from 'react-cookies'

import defaultState from '../utils/default-state'

export default function appSettingReducers(state = defaultState(), action) {
  switch (action.type) {
    case 'SET_LANGUAGE':
      cookie.save('language', action.value, { path: '/' })
      return { ...state, language: action.value }
    default:
      return state
  }
}
